<template>
    <div>
        <div class="accordion" id="accordion">
            <div class="card">
                <div class="card-header" id="headingFour">
                    <h2 class="mb-0">
                        <button class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                        Log
                        </button>
                    </h2>
                </div>
                <div id="collapseFour" class="collapse" aria-labelledby="headingFour">
                    <div class="card-body text-left">
<pre>
{{ log }}
</pre>
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-header" id="headingOne">
                    <h2 class="mb-0">
                        <button class="btn btn-link btn-block text-left" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                            Eshop objednávka
                        </button>
                    </h2>
                </div>

                <div id="collapseOne" class="collapse" aria-labelledby="headingOne">
                    <div class="card-body text-left">
<pre>
{{ objednavka }}
</pre>
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-header" id="headingTwo">
                    <h2 class="mb-0">
                        <button class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                            Objednávka mail
                        </button>
                    </h2>
                </div>
                <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo">
                    <div class="card-body text-left">
<pre>
{{ objednavka_mail }}
</pre>
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-header" id="headingThree">
                    <h2 class="mb-0">
                        <button class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                        Objednávka mail def
                        </button>
                    </h2>
                </div>
                <div id="collapseThree" class="collapse" aria-labelledby="headingThree">
                    <div class="card-body text-left">
<pre>
{{ objednavka_mail_def }}
</pre>
                    </div>
                </div>
            </div>
        </div>

        <b-button
            variant="primary"
            class="mb-2 mt-3 btn-sm-block"
            :to="{ name: 'default-view', query: { path: path }}"
        >
            {{ $t("Zpět") }}
        </b-button>
    </div>
</template>

<script>
//import * as constants from '@/store/constants.js'
import { /*state,*/ mutations } from '@/store/index.js'

export default {
    components: { },

    computed: { },

    data( ) {
        return {
            log: null,
            objednavka: null,
            objednavka_mail: null,
            objednavka_mail_def: null,
            path: null
        }
    },

    mounted( ) {
        const elem = document.getElementById("app")
        //elem.style.color = newColor
        elem.style.maxWidth = "100%"

        let params = this.$route.query
        console.log("InfoView.params", params)

        this.infoPayment(params)
    },

    methods: {
        infoPayment(platbaData) {
            mutations.infoPayment(platbaData)
                .then((response) => {
                    console.log("InfoView.infoPayment( )", response)

                    this.log = response.log

                    this.objednavka = response.objednavka
                    
                    this.objednavka_mail = response.objednavka_mail
                    this.objednavka_mail.eshop_data = "Viz. Objednávka ..."

                    this.objednavka_mail_def = response.objednavka_mail_def
                    this.objednavka_mail_def.popis_www = " ..."

                    this.path = response.path
                })
                .catch(errors => {
                    mutations.processPaymentDataErrors(errors)
                })
        }
    }
}
</script>

<style scoped>
</style>